.reject-template_main {
  padding: 0.666667rem 0.933333rem 0.4rem 0.933333rem;
  width: 14.933333rem;
  border-radius: 0.133333rem;
  background: #ffffff;
}

.reject-template_main .reject-header {
  border-bottom: 0.026667rem dashed rgba(98,112,152,0.15);
  margin-bottom: 0.533333rem;
}

.reject-template_main .reject-header .reject-header_title {
  height: 0.88rem;
  font-size: 0.64rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.88rem;
  margin-bottom: 0.16rem;
}

.reject-template_main .reject-header .reject-header_tip {
  height: 0.88rem;
  font-size: 0.426667rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #aaaaaa;
  line-height: 0.88rem;
  margin-bottom: 0.16rem;
}

.reject-template_main .reject-content {
  padding-bottom: 0.56rem;
  border-bottom: 0.026667rem dashed rgba(98,112,152,0.15);
}

.reject-template_main .reject-content .reject-content_item {
  margin-bottom: 0.266667rem;
  line-height: 0.533333rem;
}

.reject-template_main .reject-content .reject-content_item /deep/ .van-checkbox {
  align-items: baseline;
}

.reject-template_main .reject-content .reject-content_item /deep/ .van-checkbox .van-checkbox__icon .van-icon {
  border-radius: 0.053333rem;
}

.reject-template_main .reject-content .reject-content_item /deep/ .van-checkbox .van-checkbox__label {
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.reject-template_main .reject-content .other-reason {
  display: flex;
  align-items: center;
  position: relative;
}

.reject-template_main .reject-content .other-reason /deep/ .van-checkbox {
  min-width: 1.6rem;
}

.reject-template_main .reject-content .other-reason /deep/.van-cell {
  padding: 0;
  border-bottom: 0.026667rem solid #dddddd;
}

.reject-template_main .reject-content .other-reason /deep/.van-cell .van-cell__value {
  display: flex;
  justify-content: space-between;
}

.reject-template_main .reject-content .other-reason /deep/.van-cell .van-cell__value .van-field__body {
  width: 100%;
}

.reject-template_main .reject-footer {
  text-align: right;
  padding-top: 0.533333rem;
}

.reject-template_main .reject-footer .van-button {
  width: 2.186667rem;
}

.reject-template_main .reject-footer .van-button+.van-button {
  margin-left: 0.426667rem;
}