.accept .logo {
  width: 7.866667rem;
}

.accept .offer-logo {
  width: 6.426667rem;
}

.accept .accept-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.6rem;
}

.accept .accept-title img {
  margin-right: 0.266667rem;
  width: 0.586667rem;
}

.accept .accept-title .title {
  font-size: 0.426667rem;
  color: #333333;
}